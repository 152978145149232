@import 'lib/core/styles/variables.module';

.container {
  background-image: url("/icons/login-with-women.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0;
  z-index: 0;
  position: relative;
  text-align: center;
  height: calc(100vh - #{$header-height} - 4em);

  .desc {
    color: $white-color;
    z-index: 1;
    width: 100%;
    position: relative;
    font-size: 3.5vh;
    padding-top: 25vh;
    padding-left: 5em;
    padding-right: 2em;
    margin: 0;

    &[data-lang='fr-FR'] {
      font-size: 3vh;
      padding-top: 29vh;
      padding-left: 5em;
      padding-right: 2em;
    }

    @media (max-height: 700px) {
      font-size: 3vh;

      &[data-lang='fr-FR'] {
        font-size: 2.5vh;
      }
    }

    @media (max-width: $breakpoint-xxl + px) {
      padding-top: 30vh;
      font-size: 3vh;

      &[data-lang='fr-FR'] {
        padding-top: 35vh;
        font-size: 2.5vh;
      }
    }

    @media (max-width: $breakpoint-xl + px) {
      padding-top: 30vh;
      font-size: 2.5vh;

      &[data-lang='fr-FR'] {
        padding-top: 35vh;
        font-size: 2vh;
      }
    }

    @media (max-width: $breakpoint-lg + px) {
      padding-top: 35vh;
      font-size: 2vh;

      &[data-lang='fr-FR'] {
        padding-top: 35vh;
        font-size: 1.5vh;
      }
    }

    @media (max-width: $breakpoint-md + px) {
      &[data-lang='fr-FR'] {
        font-size: 1.5vh;
      }
    }

    .higher {
      display: block;
      font-size: 5em;
      font-weight: bold;
    }
  }
}
