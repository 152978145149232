@import 'lib/core/styles/variables.module';

.title {
  font-size: 2em;
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
}

.container {
  height: calc(100vh - #{$header-height} - 1em);

  .content-grid {
    height: calc(100vh - #{$header-height} - 2em);
    background: $white-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow-light;
    overflow: auto;

    .content {

      .form {
        //width: 55%;
        text-align: center;
      }

      > div {
        width: 100%;
      }

      .linkAccount {
        text-align: center;
      }
      .sub-content {
        margin-top: -1em;
      }

      .button {
        margin: 2em 0;
      }
    }
  }
}