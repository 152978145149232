@import 'lib/core/styles/variables.module';

.header {
  border-radius: $border-radius;
  box-shadow: $box-shadow-light;
  background-color: $white-color;

  .container {

    .right {
      & > button {
        margin: 0 .5em 0 0;
        min-width: initial;
      }

      .link-container {
        text-decoration: none;
        text-transform: initial !important;
        a {
          text-transform: initial !important;
        }
      }
    }
  }
}